<template>
  <div class="relative">
    <MsgBanner
      class="top-banner"
      @bannerTriggered="getBannerAvailability"
    />
    <!-- <Alert /> -->
    <!-- <div style="z-index:10" class="snow-animation">
      <Snow />
    </div> -->
    <layout-vertical>

      <router-view :key="$route.fullPath" />

      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
    </layout-vertical>
  </div>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
// import Snow from '@/components/Snow/Snow.vue'
import Navbar from '../components/Navbar.vue'
// import Alert from '../../components/Notification/Alert.vue'
import MsgBanner from '../../components/Notification/msg_banner.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    // Alert,
    // Snow,
    MsgBanner,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
<style>
@media print {
  /* Global Styles */

  /* Exclude snow animation from print */
  .snow-animation {
    display: none !important;
  }
  /* Exclude top banner from print */
  .top-banner {
    display: none !important;
  }
  /* Rest of your existing print styles */
}
</style>
