<template>
  <div
    v-if="isBannnerShown"
    class="notification-banner fix-class fixed-top"
  >
    <b-alert
      v-for="(banner, index) in bannerData"
      :key="index"
      class="custom-alert"
      show
      dismissible
      :variant="null"
      :style="{ backgroundColor: banner.banner_data.color }"
      @dismissed="bannerClosed(index)"
    >
      <div class="alert-body custom-alert notification-banner">
        <p class="notification-subject">
          <strong>
            {{ banner.subject }}
          </strong>
        </p>
        <span
          class="notification-content"
          v-html="banner.notification_body"
        >
          {{ removeHtmlTags(banner.notification_body) }}
        </span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import ErrorMessage from '@core/mixins/ErrorMessage'

const bannerRepository = RepositoryFactory.get('banner')
export default {
  components: {
    BAlert,
  },
  mixins: [ErrorMessage],
  data() {
    return {
      bannerData: null,
      isBannnerShown: false,
      isStaff: -1,
      color: '',
      bannerHeight: '5.5em',
    }
  },
  // created() {
  //   this.fetchBannerData()
  // },
  mounted() {
    this.fetchBannerData()
  },
  methods: {
    closeBtn() {
      this.bannerData.is_banner_alert_active = 0
    },
    removeHtmlTags(input) {
      const doc = new DOMParser().parseFromString(input, 'text/html')
      return doc.body.textContent || ''
    },
    async fetchBannerData() {
      try {
        const bannerDataResponse = this.$route.name === 'login' ? await bannerRepository.getActiveBannerNoAuth() : await bannerRepository.getActiveBannerAuth()
        this.bannerData = bannerDataResponse.data.data
        if (this.bannerData && this.bannerData.length > 0) {
          this.isBannnerShown = true
        } else {
          this.isBannnerShown = false
        }
      } catch (fetchError) {
        // Check if error is due to unauthenticated access
        if (fetchError.response && fetchError.response.status === 401 && this.$route.name === 'login') {
          // User is unauthenticated, fetch unauthenticated banner data
          try {
            const unauthBannerDataResponse = await bannerRepository.getActiveBannerNoAuth()
            this.bannerData = unauthBannerDataResponse.data.data
            if (this.bannerData && this.bannerData.length > 0) {
              this.isBannnerShown = true
            } else {
              this.isBannnerShown = false
            }
          } catch (error) {
            // Handle error fetching unauthenticated banner data
            this.convertAndNotifyError(error)
          }
        } else {
          // Handle other errors
          this.convertAndNotifyError(fetchError)
        }
      }
    },
    isExpired(date) {
      const now = new Date()
      return date < now
    },
    bannerClosed(index) {
      this.bannerData.splice(index, 1)
      if (this.bannerData.length === 0) {
        this.isBannnerShown = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";

@media (max-width: 600px) {
  .alert-body {
    font-size: 8px;
  }
  .notification-content{
    word-wrap: break-word;
    white-space: normal;
    max-width: 90%;
  }
  .notification-content p{
    line-height: 0.75rem !important;
  }
  .notification-subject{
    word-wrap: break-word;
    white-space: normal;
    max-width: 90%;
    line-height: 0.75rem !important;
  }
}
@media (max-width: 768px) and (min-width: 601px) {
  .alert-body {
    font-size: 10px;
  }
  .notification-content{
    word-wrap: break-word;
    white-space: normal;
    max-width: 100%;
  }
  .notification-content p{
    line-height: 0.75rem !important;
  }
  .notification-subject{
    word-wrap: break-word;
    white-space: normal;
    max-width: 90%;
    line-height: 0.75rem !important;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .alert-body {
    font-size: 10px;
  }
  .notification-content{
    word-wrap: break-word;
    white-space: normal;
    max-width: 100%;
  }
  .notification-content p{
    line-height: 0.75rem !important;
  }
  .notification-subject{
    word-wrap: break-word;
    white-space: normal;
    max-width: 90%;
    line-height: 0.75rem !important;
  }
}
.notification-banner {
  text-align: center;
  width: 100%;
  transition: height 0.3s ease;
  border-radius: 0px;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 0;
  color: white;
}

.custom-alert {
  height: 60px;
  margin-bottom: 0;
  padding-bottom: 0;
  color: white;
}
.alert-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
:root {
  --dark-bg: #1e1e1e;
  --dark-text: #ffffff;
}
.alert.alert-dismissible .close {
  padding: 1rem;
  color: var(--dark-text)
}
.fix-class {
  position: sticky;
  top: 0;
}
</style>
